export function formatThousandNumber(n) {
  // format number 1000000 to 1,234,567
  return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}


export function formattaValuta(numero, separatoreDecimale = ',', separatoreMigliaia = '.', cifreDecimali = 2, simbolo = '') {
  let [intero, decimale] = Number(numero).toFixed(cifreDecimali).split('.');

  intero = intero.replace(/\B(?=(\d{3})+(?!\d))/g, separatoreMigliaia);

  decimale = decimale ? decimale : '';

  return `${simbolo}${intero}${separatoreDecimale}${decimale}`;
}