import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { PROVINCES } from '../../constants/provinces';

export const renderProvincesListServerSide = async props => {
  const { title, provinces, provincesToShow } = props;

  return `<ProvincesList title="${title}" provinces="${provinces}" provincestoshow="${provincesToShow}" />`;
};

const maxColumns = 5;

const ProvincesList = ({ title, provinces, provincestoshow }) => {
  const extendedProvinces = provinces?.split(',')?.map(abb => PROVINCES.find(p => p.abbreviation === abb)?.name);
  const [elementToShow, setElementToShow] = useState(provincestoshow || extendedProvinces?.length);

  const onShowMoreClick = () => {
    setElementToShow(extendedProvinces.length);
  };

  if (!extendedProvinces || extendedProvinces?.length === 0) return null;

  return (
    <Box>
      {title && (
        <Typography
          sx={{
            fontFamily: 'var(--font-red-hat-display)',
            textAlign: 'center',
            fontSize: 'clamp(1.5rem, 0.9278rem + 1.831vw, 3.125rem)',
            fontWeight: 'bold',
            py: 4,
          }}>
          {title}
        </Typography>
      )}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: `repeat(auto-fill, minmax(180px, calc(100% / ${maxColumns} - 16px)))`,
          gap: '16px',
          justifyContent: 'center',
        }}>
        {extendedProvinces.slice(0, elementToShow).map(p => (
          <Box
            title={`Broker mutui - ${p}`}
            component="a"
            key={uuidv4()}
            href={`/broker-mutui/${p?.toLowerCase().replaceAll(' ', '-')}`}
            sx={{
              border: '2px solid #0A92DE',
              textAlign: 'center',
              p: 1,
              borderRadius: '3px',
              boxShadow: '0px 6px 6px #00000029',
              textDecoration: 'none !important',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Typography
              sx={{
                textTransform: 'uppercase',
                fontFamily: 'var(--font-red-hat-display)',
                textAlign: 'center',
                fontSize: 'clamp(1.115rem, 0.971rem + 0.483vw, 1.2625rem)',
                fontWeight: 'bold',
                color: '#103C55',
              }}>
              {p}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        {elementToShow < extendedProvinces.length && (
          <Button onClick={onShowMoreClick}>
            <Typography
              sx={{
                fontFamily: 'var(--font-red-hat-display)',
                textDecoration: 'underline',
                fontSize: 'clamp(1.115rem, 0.971rem + 0.483vw, 1.2625rem)',
                textTransform: 'none',
              }}>
              {'Scopri tutte le altre città >>'}
            </Typography>
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ProvincesList;
