const PROVINCES = [
  {
    _id: {
      $oid: '65e33a73d333b86dc74423a7',
    },
    name: 'Agrigento',
    abbreviation: 'AG',
    region: 'Sicilia',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423a8',
    },
    name: 'Alessandria',
    abbreviation: 'AL',
    region: 'Piemonte',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423a9',
    },
    name: 'Ancona',
    abbreviation: 'AN',
    region: 'Marche',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423aa',
    },
    name: 'Arezzo',
    abbreviation: 'AR',
    region: 'Toscana',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423ab',
    },
    name: 'Ascoli Piceno',
    abbreviation: 'AP',
    region: 'Marche',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423ac',
    },
    name: 'Asti',
    abbreviation: 'AT',
    region: 'Piemonte',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423ad',
    },
    name: 'Avellino',
    abbreviation: 'AV',
    region: 'Campania',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423ae',
    },
    name: 'Bari',
    abbreviation: 'BA',
    region: 'Puglia',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423af',
    },
    name: 'Barletta-Andria-Trani',
    abbreviation: 'BT',
    region: 'Puglia',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423b0',
    },
    name: 'Belluno',
    abbreviation: 'BL',
    region: 'Veneto',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423b1',
    },
    name: 'Benevento',
    abbreviation: 'BN',
    region: 'Campania',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423b2',
    },
    name: 'Bergamo',
    abbreviation: 'BG',
    region: 'Lombardia',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423b3',
    },
    name: 'Biella',
    abbreviation: 'BI',
    region: 'Piemonte',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423b4',
    },
    name: 'Bologna',
    abbreviation: 'BO',
    region: 'Emilia-Romagna',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423b5',
    },
    name: 'Bolzano/Bozen',
    abbreviation: 'BZ',
    region: 'Trentino-Alto Adige/Südtirol',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423b6',
    },
    name: 'Brescia',
    abbreviation: 'BS',
    region: 'Lombardia',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423b7',
    },
    name: 'Brindisi',
    abbreviation: 'BR',
    region: 'Puglia',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423b8',
    },
    name: 'Cagliari',
    abbreviation: 'CA',
    region: 'Sardegna',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423b9',
    },
    name: 'Caltanissetta',
    abbreviation: 'CL',
    region: 'Sicilia',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423ba',
    },
    name: 'Campobasso',
    abbreviation: 'CB',
    region: 'Molise',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423bb',
    },
    name: 'Carbonia-Iglesias',
    abbreviation: 'CI',
    region: 'Sardegna',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423bc',
    },
    name: 'Caserta',
    abbreviation: 'CE',
    region: 'Campania',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423bd',
    },
    name: 'Catania',
    abbreviation: 'CT',
    region: 'Sicilia',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423be',
    },
    name: 'Catanzaro',
    abbreviation: 'CZ',
    region: 'Calabria',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423bf',
    },
    name: 'Chieti',
    abbreviation: 'CH',
    region: 'Abruzzo',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423c0',
    },
    name: 'Como',
    abbreviation: 'CO',
    region: 'Lombardia',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423c1',
    },
    name: 'Cosenza',
    abbreviation: 'CS',
    region: 'Calabria',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423c2',
    },
    name: 'Cremona',
    abbreviation: 'CR',
    region: 'Lombardia',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423c3',
    },
    name: 'Crotone',
    abbreviation: 'KR',
    region: 'Calabria',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423c4',
    },
    name: 'Cuneo',
    abbreviation: 'CN',
    region: 'Piemonte',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423c5',
    },
    name: 'Enna',
    abbreviation: 'EN',
    region: 'Sicilia',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423c6',
    },
    name: 'Fermo',
    abbreviation: 'FM',
    region: 'Marche',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423c7',
    },
    name: 'Ferrara',
    abbreviation: 'FE',
    region: 'Emilia-Romagna',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423c8',
    },
    name: 'Firenze',
    abbreviation: 'FI',
    region: 'Toscana',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423c9',
    },
    name: 'Foggia',
    abbreviation: 'FG',
    region: 'Puglia',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423ca',
    },
    name: 'Forlì-Cesena',
    abbreviation: 'FC',
    region: 'Emilia-Romagna',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423cb',
    },
    name: 'Frosinone',
    abbreviation: 'FR',
    region: 'Lazio',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423cc',
    },
    name: 'Genova',
    abbreviation: 'GE',
    region: 'Liguria',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423cd',
    },
    name: 'Gorizia',
    abbreviation: 'GO',
    region: 'Friuli-Venezia Giulia',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423ce',
    },
    name: 'Grosseto',
    abbreviation: 'GR',
    region: 'Toscana',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423cf',
    },
    name: 'Imperia',
    abbreviation: 'IM',
    region: 'Liguria',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423d0',
    },
    name: 'Isernia',
    abbreviation: 'IS',
    region: 'Molise',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423d1',
    },
    name: "L'Aquila",
    abbreviation: 'AQ',
    region: 'Abruzzo',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423d2',
    },
    name: 'La Spezia',
    abbreviation: 'SP',
    region: 'Liguria',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423d3',
    },
    name: 'Latina',
    abbreviation: 'LT',
    region: 'Lazio',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423d4',
    },
    name: 'Lecce',
    abbreviation: 'LE',
    region: 'Puglia',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423d5',
    },
    name: 'Lecco',
    abbreviation: 'LC',
    region: 'Lombardia',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423d6',
    },
    name: 'Livorno',
    abbreviation: 'LI',
    region: 'Toscana',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423d7',
    },
    name: 'Lodi',
    abbreviation: 'LO',
    region: 'Lombardia',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423d8',
    },
    name: 'Lucca',
    abbreviation: 'LU',
    region: 'Toscana',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423d9',
    },
    name: 'Macerata',
    abbreviation: 'MC',
    region: 'Marche',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423da',
    },
    name: 'Mantova',
    abbreviation: 'MN',
    region: 'Lombardia',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423db',
    },
    name: 'Massa-Carrara',
    abbreviation: 'MS',
    region: 'Toscana',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423dc',
    },
    name: 'Matera',
    abbreviation: 'MT',
    region: 'Basilicata',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423dd',
    },
    name: 'Medio Campidano',
    abbreviation: 'VS',
    region: 'Sardegna',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423de',
    },
    name: 'Messina',
    abbreviation: 'ME',
    region: 'Sicilia',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423df',
    },
    name: 'Milano',
    abbreviation: 'MI',
    region: 'Lombardia',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423e0',
    },
    name: 'Modena',
    abbreviation: 'MO',
    region: 'Emilia-Romagna',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423e1',
    },
    name: 'Monza e della Brianza',
    abbreviation: 'MB',
    region: 'Lombardia',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423e2',
    },
    name: 'Napoli',
    abbreviation: 'NA',
    region: 'Campania',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423e3',
    },
    name: 'Novara',
    abbreviation: 'NO',
    region: 'Piemonte',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423e4',
    },
    name: 'Nuoro',
    abbreviation: 'NU',
    region: 'Sardegna',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423e5',
    },
    name: 'Ogliastra',
    abbreviation: 'OG',
    region: 'Sardegna',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423e6',
    },
    name: 'Olbia-Tempio',
    abbreviation: 'OT',
    region: 'Sardegna',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423e7',
    },
    name: 'Oristano',
    abbreviation: 'OR',
    region: 'Sardegna',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423e8',
    },
    name: 'Padova',
    abbreviation: 'PD',
    region: 'Veneto',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423e9',
    },
    name: 'Palermo',
    abbreviation: 'PA',
    region: 'Sicilia',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423ea',
    },
    name: 'Parma',
    abbreviation: 'PR',
    region: 'Emilia-Romagna',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423eb',
    },
    name: 'Pavia',
    abbreviation: 'PV',
    region: 'Lombardia',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423ec',
    },
    name: 'Perugia',
    abbreviation: 'PG',
    region: 'Umbria',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423ed',
    },
    name: 'Pesaro e Urbino',
    abbreviation: 'PU',
    region: 'Marche',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423ee',
    },
    name: 'Pescara',
    abbreviation: 'PE',
    region: 'Abruzzo',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423ef',
    },
    name: 'Piacenza',
    abbreviation: 'PC',
    region: 'Emilia-Romagna',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423f0',
    },
    name: 'Pisa',
    abbreviation: 'PI',
    region: 'Toscana',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423f1',
    },
    name: 'Pistoia',
    abbreviation: 'PT',
    region: 'Toscana',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423f2',
    },
    name: 'Pordenone',
    abbreviation: 'PN',
    region: 'Friuli-Venezia Giulia',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423f3',
    },
    name: 'Potenza',
    abbreviation: 'PZ',
    region: 'Basilicata',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423f4',
    },
    name: 'Prato',
    abbreviation: 'PO',
    region: 'Toscana',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423f5',
    },
    name: 'Ragusa',
    abbreviation: 'RG',
    region: 'Sicilia',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423f6',
    },
    name: 'Ravenna',
    abbreviation: 'RA',
    region: 'Emilia-Romagna',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423f7',
    },
    name: 'Reggio di Calabria',
    abbreviation: 'RC',
    region: 'Calabria',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423f8',
    },
    name: "Reggio Emilia",
    abbreviation: 'RE',
    region: 'Emilia-Romagna',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423f9',
    },
    name: 'Rieti',
    abbreviation: 'RI',
    region: 'Lazio',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423fa',
    },
    name: 'Rimini',
    abbreviation: 'RN',
    region: 'Emilia-Romagna',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423fb',
    },
    name: 'Roma',
    abbreviation: 'RM',
    region: 'Lazio',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423fc',
    },
    name: 'Rovigo',
    abbreviation: 'RO',
    region: 'Veneto',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423fd',
    },
    name: 'Salerno',
    abbreviation: 'SA',
    region: 'Campania',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423fe',
    },
    name: 'Sassari',
    abbreviation: 'SS',
    region: 'Sardegna',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc74423ff',
    },
    name: 'Savona',
    abbreviation: 'SV',
    region: 'Liguria',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc7442400',
    },
    name: 'Siena',
    abbreviation: 'SI',
    region: 'Toscana',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc7442401',
    },
    name: 'Siracusa',
    abbreviation: 'SR',
    region: 'Sicilia',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc7442402',
    },
    name: 'Sondrio',
    abbreviation: 'SO',
    region: 'Lombardia',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc7442403',
    },
    name: 'Taranto',
    abbreviation: 'TA',
    region: 'Puglia',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc7442404',
    },
    name: 'Teramo',
    abbreviation: 'TE',
    region: 'Abruzzo',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc7442405',
    },
    name: 'Terni',
    abbreviation: 'TR',
    region: 'Umbria',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc7442406',
    },
    name: 'Torino',
    abbreviation: 'TO',
    region: 'Piemonte',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc7442407',
    },
    name: 'Trapani',
    abbreviation: 'TP',
    region: 'Sicilia',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc7442408',
    },
    name: 'Trento',
    abbreviation: 'TN',
    region: 'Trentino-Alto Adige/Südtirol',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc7442409',
    },
    name: 'Treviso',
    abbreviation: 'TV',
    region: 'Veneto',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc744240a',
    },
    name: 'Trieste',
    abbreviation: 'TS',
    region: 'Friuli-Venezia Giulia',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc744240b',
    },
    name: 'Udine',
    abbreviation: 'UD',
    region: 'Friuli-Venezia Giulia',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc744240c',
    },
    name: "Valle d'Aosta/Vallée d'Aoste",
    abbreviation: 'AO',
    region: "Valle d'Aosta/Vallée d'Aoste",
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc744240d',
    },
    name: 'Varese',
    abbreviation: 'VA',
    region: 'Lombardia',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc744240e',
    },
    name: 'Venezia',
    abbreviation: 'VE',
    region: 'Veneto',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc744240f',
    },
    name: 'Verbano-Cusio-Ossola',
    abbreviation: 'VB',
    region: 'Piemonte',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc7442410',
    },
    name: 'Vercelli',
    abbreviation: 'VC',
    region: 'Piemonte',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc7442411',
    },
    name: 'Verona',
    abbreviation: 'VR',
    region: 'Veneto',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc7442412',
    },
    name: 'Vibo Valentia',
    abbreviation: 'VV',
    region: 'Calabria',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc7442413',
    },
    name: 'Vicenza',
    abbreviation: 'VI',
    region: 'Veneto',
  },
  {
    _id: {
      $oid: '65e33a73d333b86dc7442414',
    },
    name: 'Viterbo',
    abbreviation: 'VT',
    region: 'Lazio',
  },
];

module.exports = {
  PROVINCES,
};
