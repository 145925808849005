import React, { useEffect, useRef } from 'react';
import Link from 'next/link';
import { Box, Popover, Typography } from '@mui/material';

const MenuItemPopover = ({ text, itemHref = null, index, setAnchorEl, anchorEl, timeoutRef, subItems }) => {
  const ref = useRef();

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    // close popover when page scroll
    setAnchorEl(null);
  };

  const handlePopoverOpen = event => {
    event.stopPropagation();
    if (subItems.length === 0) {
      return;
    }
    clearTimeout(timeoutRef.current);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const onLeaveItem = () => {
    timeoutRef.current = setTimeout(() => {
      handlePopoverClose();
    }, 200);
  };

  const onPopoverContentEnter = () => {
    clearTimeout(timeoutRef.current);
  };

  const open = Boolean(anchorEl === ref.current);

  let subItemsArr1 = [];
  let subItemsArr2 = [];

  if (subItems.length < 8) {
    subItemsArr1 = subItems;
  } else {
    const arrLength = subItems.length;
    const middleLength = Math.ceil(arrLength / 2);

    subItemsArr1 = subItems.slice(0, middleLength);
    subItemsArr2 = subItems.slice(middleLength);
  }

  return (
    <>
      <Box
        ref={ref}
        id={index}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={onLeaveItem}
        sx={{ py: 1.5, px: 2, borderLeft: index > 0 ? '2px solid white' : '', flex: 1 }}>
        {itemHref ? (
          <Link href={`/${itemHref}`}>
            
              <Typography
                sx={{
                  fontFamily: 'var(--font-red-hat-display)',
                  textTransform: 'uppercase',
                  color: 'white',
                  fontSize: '16px',
                  textAlign: 'center',
                }}>
                {text}
              </Typography>
            
          </Link>
        ) : (
          <Typography
            sx={{
              fontFamily: 'var(--font-red-hat-display)',
              textTransform: 'uppercase',
              color: 'white',
              fontSize: '16px',
              textAlign: 'center',
              cursor: 'pointer',
            }}>
            {text}
          </Typography>
        )}
      </Box>

      <Popover
        id="mouse-over-popover"
        disableScrollLock
        sx={{
          pointerEvents: 'none',
        }}
        open
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            borderRadius: 0,
            boxShadow: 'none',
            display: open ? 'block' : 'none',
          },
        }}>
        <Box
          onMouseEnter={onPopoverContentEnter}
          onMouseLeave={handlePopoverClose}
          sx={{
            pointerEvents: 'auto',
            filter: 'drop-shadow(0 2px 4px rgba(0, 0, 0, 0.05))',
            px: 1,
            pb: 1,
          }}>
          <Box
            sx={{
              position: 'relative',
              mt: '10px',
              '&::before': {
                backgroundColor: 'white',
                content: '""',
                display: 'block',
                position: 'absolute',
                width: 12,
                height: 12,
                top: -6,
                transform: 'rotate(45deg)',
                left: 'calc(50% - 6px)',
              },
            }}
          />
          <Box sx={{ bgcolor: 'white', borderRadius: '8px', p: 2, display: 'flex' }}>
            <Box>
              {subItemsArr1?.map(
                i =>
                  i?.page?.path && (
                    <Box key={i.id} onClick={handlePopoverClose}>
                      <Link href={`/${i.page.path}`}>
                        
                          <Typography
                            sx={{ fontFamily: 'var(--font-red-hat-display)', color: '#103C55', fontSize: '18px', px: 1, py: 1, minWidth: '120px' }}>
                            {i.name}
                          </Typography>
                        
                      </Link>
                    </Box>
                  ),
              )}
            </Box>
            {subItemsArr2.length > 0 && <Box sx={{ width: '1px', flex: 1, bgcolor: '#DDDDDD', mx: 2 }} />}
            <Box>
              {subItemsArr2?.map(
                i =>
                  i?.page?.path && (
                    <Box key={i.id} onClick={handlePopoverClose}>
                      <Link href={`/${i.page.path}`}>
                        
                          <Typography
                            sx={{ fontFamily: 'var(--font-red-hat-display)', color: '#103C55', fontSize: '18px', px: 1, py: 1, minWidth: '120px' }}>
                            {i.name}
                          </Typography>
                       
                      </Link>
                    </Box>
                  ),
              )}
            </Box>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default MenuItemPopover;
