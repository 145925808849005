import React from 'react';
import { Box } from '@mui/material';
import ReactDOMServer from 'react-dom/server';

import { getBrokersByProvinces } from '../../api/broker/broker';
import config from '../../config/config';
import BrokerListCard from './BrokerListCard';

export const renderBrokerListServerSide = async props => {
  const { provinces } = props;

  const response = await getBrokersByProvinces(provinces);

  let brokers = [];
  if (response?.data && response?.data?.length > 0) {
    brokers = response?.data;
  }

  return ReactDOMServer.renderToString(<BrokerList brokers={brokers} />);
};

const BrokerList = ({ brokers = [] }) => {
  if (brokers.length === 0) return null;

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', gap: '24px', my: 2 }}>
      {brokers.map(b => (
        <BrokerListCard
          key={b._id}
          profileImageUrl={`${config.intranetBaseUrl}${b.tassomutuoProfile?.profileImageUrl}`}
          name={b.name}
          lastName={b.lastName}
          htmlDescription={b.tassomutuoProfile?.htmlDescription}
          provinces={b?.tassomutuoProfile?.extendedProvinces?.join(', ')}
        />
      ))}
    </Box>
  );
};

export default BrokerList;
