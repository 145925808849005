import React, { useState } from 'react';
import { useRouter } from 'next/router';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Drawer, List, ListItemText, Box, ListItemButton, Collapse, Typography } from '@mui/material';

const DRAWER_WIDTH = 280;
const MenuDrawer = ({ open, onClose, menuItems }) => {
  const router = useRouter();

  const [openItem, setOpenItem] = useState(null);

  const handleClick = newVal => {
    if (openItem === newVal) {
      setOpenItem(null);
    } else {
      setOpenItem(newVal);
    }
  };
  const onClickSubItem = subItem => {
    router.push('/' + subItem?.page?.path);
    onClose();
  };
  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <Box sx={{ width: DRAWER_WIDTH, bgcolor: '#1878B4', flex: 1, pt: 10 }} role="presentation">
        <List>
          {menuItems?.map(i => (
            <Box key={i.id}>
              <ListItemButton key={i.id} onClick={() => handleClick(i.id)} sx={{ color: 'white', textTransform: 'uppercase' }}>
                <ListItemText disableTypography primary={<Typography style={{ fontFamily: 'var(--font-red-hat-display)' }}>{i.name}</Typography>} />
                {openItem === i.id ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openItem === i.id} timeout="auto" unmountOnExit sx={{ bgcolor: 'white' }}>
                <List component="div" disablePadding>
                  {i?.subItems.map(si => (
                    <ListItemButton key={si.id} sx={{ py: 0 }} onClick={() => onClickSubItem(si)}>
                      <ListItemText
                        disableTypography
                        primary={<Typography style={{ fontFamily: 'var(--font-red-hat-display)' }}>{si.name}</Typography>}
                      />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </Box>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default MenuDrawer;
