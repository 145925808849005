import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import Slider from 'react-slick';

import QuotationCarouselCard from './QuotationCarouselCard';
import getPreventivo from '../../api/getPreventivo';
import getBanche from '../../api/getBanche';
import { getEstimatorQueriesCacheByIds } from '../../api/estimator/estimatorsQueriesCache';
import { getBankImage } from '../../utilities/banks';
import { useOnRenderedContext } from '../../context/OnRenderedContext';

export const getQuotationListForCarouselQuotation = async estimatorQueriesCacheIds => {
  let quotationList = [];

  const responseQueriesCache = await getEstimatorQueriesCacheByIds(estimatorQueriesCacheIds);
  const { data: queriesCacheData } = responseQueriesCache;
  queriesCacheData.forEach((query, index) => {
    const quotes = query.queryResult.preventivi;
    const banksList = Object.keys(quotes);
    let quotesToConsider = [];

    if (banksList.length === 1) {
      quotesToConsider = quotes[banksList[0]];
    } else {
      quotesToConsider = banksList.map(bankName => quotes[bankName][0]);
    }

    // se è richiesto i preventivi di una sola query allora prende tutti i preventivi della singola query
    if (index === 0 && estimatorQueriesCacheIds.length === 1) {
      quotationList = quotesToConsider.map(item => ({
        prodottoId: item.prodottoId,
        rata: item.rata,
        tan: item.tan,
        taeg: item.taeg,
        tipotasso: item.tipotasso,
        infoMutuo: item.infoMutuo,
        nomebanca: item.nomebanca,
        //bankImage: getBankImage(responseBanks?.banche, item?.nomebanca),
        durata: query.queryBody.durata,
        importo: query.queryBody.importo,
      }));
    }
    // se sono richiesto i preventivi di  più query allora prende solo il primo preventivo di ogni query
    if (estimatorQueriesCacheIds.length > 1) {
      const firstQuote = quotesToConsider[0];
      quotationList = [
        ...quotationList,
        {
          prodottoId: firstQuote.prodottoId,
          rata: firstQuote.rata,
          tan: firstQuote.tan,
          taeg: firstQuote.taeg,
          tipotasso: firstQuote.tipotasso,
          infoMutuo: firstQuote.infoMutuo,
          nomebanca: firstQuote.nomebanca,
          //bankImage: getBankImage(responseBanks?.banche, item?.nomebanca),
          durata: query.queryBody.durata,
          importo: query.queryBody.importo,
        },
      ];
    }
  });

  const responseBanks = await getBanche();

  return quotationList.map(i => ({ ...i, bankImage: getBankImage(responseBanks?.banche, i?.nomebanca) }));
};

// Function to render CarouselQuotation server side
export const renderCarouselQuotationServerSide = async props => {
  const {
    preventiviIds,
    nomeBanca = 'Tutte',
    importo,
    valoreImmobile,
    tipoTasso,
    finalita,
    durata,
    ordina = 'PerRata',
    eta,
    title,
    preventiviDaMostrare = 3,
    ctaTitle,
  } = props;

  let quotationList = [];
  if (!preventiviIds) {
    const responsePreventivi = await getPreventivo({
      nomebanca: nomeBanca,
      importo,
      valoreImmobile,
      tipoTasso,
      finalita,
      durata,
      ordina,
      eta,
    });

    if (responsePreventivi.preventivi) {
      quotationList = Object.keys(responsePreventivi.preventivi).map(bankName => responsePreventivi.preventivi[bankName][0]);
    }

    const responseBanks = await getBanche();

    const quotationListMapped = quotationList?.map(item => ({
      prodottoId: item.prodottoId,
      rata: item.rata,
      tan: item.tan,
      taeg: item.taeg,
      tipotasso: item.tipotasso,
      infoMutuo: item.infoMutuo,
      nomebanca: item.nomebanca,
      bankImage: getBankImage(responseBanks?.banche, item?.nomebanca),
      durata,
      importo,
    }));

    const propsToSend = { quotationList: quotationListMapped, title, preventiviDaMostrare, ctaTitle };

    // props are passed in children ti prevent parsing error from html-react-parser
    return `<CarouselQuotation
            >${JSON.stringify(propsToSend)}</CarouselQuotation>`;
  } else {
    const preventiviIdsArr = preventiviIds.split(',');

    const quotationListMapped = await getQuotationListForCarouselQuotation(preventiviIdsArr);

    const propsToSend = { quotationList: quotationListMapped, title, preventiviDaMostrare, ctaTitle };

    // props are passed in children ti prevent parsing error from html-react-parser
    return `<CarouselQuotation
            >${JSON.stringify(propsToSend)}</CarouselQuotation>`;
  }
};

const settings = {
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  arrows: true,
  initialSlide: 0,
  autoplaySpeed: 8000,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const SLIDES_TO_SHOW = 3;

const CarouselQuotation = ({ title, preventiviDaMostrare = 3, ctaTitle = 'Verifica fattibilità', quotationList = [], showCardCta = true }) => {
  const [quotationToShow, setQuotationToShow] = useState(SLIDES_TO_SHOW);
  const [, setOnRendered] = useOnRenderedContext();

  useEffect(()=>{setOnRendered(true, 'carouselQuotation');}, []);
  
  const onShowOtherClick = () => {
    setQuotationToShow(val => val + SLIDES_TO_SHOW);
  };
  const onShowLessClick = () => {
    setQuotationToShow(SLIDES_TO_SHOW);
  };
  
  const quotationListToShow = quotationList?.slice(0, preventiviDaMostrare);
  
  if (quotationList?.length === 0) return null;
  
  return (
    <Box
      sx={{
        padding: { xs: '10px 0 60px', md: '10px 60px 60px' },
        backgroundColor: '#F4F3F4',
        width: '100vw',
        marginLeft: 'calc(50% - 50vw)',
        marginRight: 'calc(50% - 50vw)',
      }}>
      <Box
        sx={{
          maxWidth: '1600px',
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'column',
        }}>
        {title && (
          <Typography
            sx={{
              fontFamily: 'var(--font-red-hat-display)',
              textAlign: 'center',
              fontSize: 'clamp(1.5rem, 0.9278rem + 1.831vw, 3.125rem)',
              fontWeight: 'bold',
              py: 4,
            }}>
            {title}
          </Typography>
        )}
        <link rel="stylesheet" type="text/css" charSet="UTF-8" href="/css/slick.min.css" />
        <link rel="stylesheet" type="text/css" href="/css/slick-theme.min.css" />
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Slider {...settings} slidesToShow={quotationList.length < 3 ? quotationList.length : 3}>
            {quotationListToShow.map(q => (
              <Box key={q.prodottoId} sx={{ p: 2 }}>
                <QuotationCarouselCard
                  rata={q?.rata}
                  duration={q?.durata}
                  importoMutuo={q?.importo}
                  tan={q?.tan}
                  taeg={q?.taeg}
                  tipoTasso={q?.tipotasso}
                  infoMutuo={q?.infoMutuo}
                  bankName={q?.nomebanca}
                  bankImage={q?.bankImage}
                  ctaTitle={ctaTitle}
                  showCta={showCardCta}
                />
              </Box>
            ))}
          </Slider>
        </Box>
        <Box sx={{ display: { md: 'none' } }}>
          {quotationListToShow.map(
            (q, index) =>
              index < quotationToShow && (
                <Box key={q.prodottoId} sx={{ p: 2 }}>
                  <QuotationCarouselCard
                    rata={q?.rata}
                    duration={q?.durata}
                    importoMutuo={q?.importo}
                    tan={q?.tan}
                    taeg={q?.taeg}
                    tipoTasso={q?.tipotasso}
                    infoMutuo={q?.infoMutuo}
                    bankName={q?.nomebanca}
                    bankImage={q?.bankImage}
                    ctaTitle={ctaTitle}
                    showCta={showCardCta}
                  />
                </Box>
              ),
          )}
          {quotationToShow < quotationListToShow.length ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={onShowOtherClick}
                disableElevation
                sx={{
                  color: '#0A92DE',
                  fontFamily: 'var(--font-red-hat-display)',
                  fontWeight: 'bold',
                  fontSize: '13px',
                  px: 4,
                  textDecoration: 'none !important',
                }}>
                Mostra altri
              </Button>
            </Box>
          ) : (
            quotationToShow > SLIDES_TO_SHOW && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Button
                  onClick={onShowLessClick}
                  disableElevation
                  sx={{
                    color: '#0A92DE',
                    fontFamily: 'var(--font-red-hat-display)',
                    fontWeight: 'bold',
                    fontSize: '13px',
                    px: 4,
                    textDecoration: 'none !important',
                  }}>
                  Comprimi
                </Button>
              </Box>
            )
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CarouselQuotation;
