import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const theme = {
  dark: {
    bgcolor: '#103C55',
    titleColor: 'white',
    ctaColor: '#3799D0',
    ctaTextColor: 'white',
  },
  light: {
    bgcolor: '#3799D0',
    titleColor: 'white',
    ctaColor: 'white',
    ctaTextColor: '#103C55',
  },
  default: {
    bgcolor: 'transparent',
    titleColor: '#3799D0',
    ctaColor: '#3799D0',
    ctaTextColor: 'white',
  },
};

const CtaBanner = ({ type, title, ctaText }) => {
  const themeSelected = theme[type] || theme.default;

  if (!title) return;

  return (
    <Box
      sx={{
        bgcolor: themeSelected?.bgcolor,
        minHeight: '300px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 2,
        m: '100px auto',
        width: '100vw',
        marginLeft: 'calc(50% - 50vw)',
        marginRight: 'calc(50% - 50vw)',
      }}>
      <Typography
        sx={{
          fontFamily: 'var(--font-red-hat-display)',
          textAlign: 'center',
          fontSize: 'clamp(1.5rem, 0.9278rem + 1.831vw, 3.125rem)',
          fontWeight: 'bold',
          color: themeSelected.titleColor,
        }}>
        {title}
      </Typography>
      {ctaText && (
        <Button
          component="a"
          href="#top"
          variant="contained"
          disableElevation
          sx={{
            bgcolor: themeSelected.ctaColor,
            color: themeSelected.ctaTextColor,
            fontFamily: 'var(--font-red-hat-display)',
            fontWeight: 'bold',
            fontSize: 'clamp(1.125rem, 0.971rem + 0.493vw, 1.5625rem)',
            mt: 6,
            px: 4,
            '&:hover': {
              bgcolor: themeSelected.ctaTextColor,
              color: themeSelected.ctaColor,
            },
            textDecoration: 'none !important',
          }}>
          {ctaText}
        </Button>
      )}
    </Box>
  );
};
export default CtaBanner;
