import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import Image from 'next/image';

import { formatThousandNumber } from '../../utilities/formatNumber';

const QuotationCarouselCard = ({ rata, duration, tan, taeg, tipoTasso, infoMutuo, importoMutuo, bankImage, bankName, ctaTitle, showCta = true }) => {
  const onCtaClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const bankLogo = bankImage.replace('http://', 'https://')

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        bgcolor: '#fff',
        p: '15px',
        fontFamily: 'var(--font-red-hat-display)',
        fontSize: '13px',
        color: '#3a3a3a',
        borderRadius: '10px',
        boxShadow: '0px 0px 15px #00000040',
        minHeight: showCta ? '360px' : '250px',
      }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ fontFamily: 'inherit', color: 'inherit', fontSize: '20px' }}>Importo rata</Typography>
        <Box sx={{ objectFit: 'contain', maxWidth: { xs: '140px', sm: '200px', md: '200px' }, maxHeight: '40px', display: 'flex', justifyContent: 'end' }}>
          <Image
            src={bankLogo}
            alt={`Preventivo mutuo ${bankName || 'richiesto'}`}
            width={1280}
            height={526}
            style={{ objectPosition: 'right center', objectFit: 'contain', maxWidth: '100%', maxHeight: '100%', height: '100%', width: 'auto' }}
          />
        </Box>
      </Box>

      <Typography
        sx={{
          fontFamily: 'inherit',
          color: '#0A92DE',
          fontWeight: 'bold',
          fontSize: { xs: '46px', md: '66px' },
          lineHeight: '80px',
          letterSpacing: '1px',
        }}>
        € {rata}
      </Typography>
      <Box sx={{ display: 'flex', py: 2, alignItems: 'flex-end' }}>
        <Box sx={{ borderRight: '1px solid #0A92DE', pr: 2 }}>
          <Typography sx={{ fontFamily: 'inherit', fontSize: 'inherit' }}>Durata: {duration} anni</Typography>
          <Typography sx={{ fontFamily: 'inherit', fontSize: 'inherit' }}>Importo mutuo: {formatThousandNumber(importoMutuo)} €</Typography>
          <Typography sx={{ fontFamily: 'inherit', fontSize: 'inherit' }}>Tasso {tipoTasso}</Typography>
        </Box>

        <Box sx={{ pl: 2 }}>
          <Typography sx={{ fontFamily: 'inherit', fontSize: 'inherit' }}>TAN: {tan}%</Typography>
          <Typography sx={{ fontFamily: 'inherit', fontSize: 'inherit' }}>TAEG: {taeg}%</Typography>
        </Box>
      </Box>
      <Typography
        sx={{
          fontFamily: 'inherit',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          lineClamp: '2',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical',
          fontSize: '12px',
          lineHeight: '16px',
        }}>
        {infoMutuo}
      </Typography>
      {showCta && (
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            onClick={onCtaClick}
            sx={{
              width: '100%',
              borderRadius: 0,
              bgcolor: '#41ba58',
              my: 2,
              '&:hover': {
                bgcolor: '#0A92DE',
              },
            }}>
            {ctaTitle}
          </Button>
          <Typography
            sx={{
              fontFamily: 'inherit',
              fontSize: 'inherit',
              textAlign: 'center',
              fontWeight: 'bold',
              color: '#41ba58',
            }}>
            GRATIS E SENZA IMPEGNO
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default QuotationCarouselCard;
