import React, { useRef, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import MenuIcon from '@mui/icons-material/Menu';
import Link from 'next/link';
import Image from 'next/image';
import MenuItemPopover from './MenuItemPopover';
import MenuDrawer from './MenuDrawer';
import CloseIcon from '@mui/icons-material/Close';

import logo_tassomutuo_dark from '../../public/logo_tassomutuo_dark.png';

const HeaderWithMenu = ({ menuItems }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const timeoutPopoverRef = useRef();

  return (
    <>
      <Box component="header" sx={{ display: 'flex', flexDirection: 'column', p: 0, position: 'relative', zIndex: 9999 }}>
        <Box sx={{ bgcolor: 'white', width: '100%', p: { xs: 2, md: 4 }, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box display="flex" alignItems="center" gap={1}>
            {menuItems && menuItems.length > 0 && (
              <IconButton sx={{ display: { xs: 'block', md: 'none' } }} onClick={() => setDrawerOpen(val => !val)}>
                {drawerOpen ? <CloseIcon sx={{ color: '#0A92DE' }} /> : <MenuIcon sx={{ color: '#0A92DE' }} />}
              </IconButton>
            )}
            <Link href="/">
              <Image
                src={logo_tassomutuo_dark}
                alt="TassoMutuo logo"
                sizes="(max-width: 768px) 180px, 280px"
                loading="eager"
                style={{ width: '100%', minWidth: '180px', maxWidth: '280px', height: 'auto' }}
                width={5129}
                height={1000}
              />
            </Link>
          </Box>
          <Box
            component="a"
            href="tel:3490516905"
            sx={{
              border: '2px solid #0A92DE',
              borderRadius: '30px',
              px: 2,
              py: 0.5,
              fontFamily: 'var(--font-red-hat-display)',
              textTransform: 'uppercase',
              color: '#0A92DE',
              '&:hover': {
                background: '#0A92DE',
                color: 'white',
              },
              display: { xs: 'none', md: 'block', lg: 'block' },
            }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
              <Box
                sx={{
                  transform: 'rotate(10deg)',
                  mt: '5px',
                }}>
                <PhoneIcon className="phoneIconHeader" fontSize="large" sx={{ color: 'inherit' }} />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: 'clamp(0.625rem, 0.515rem + 0.3521vw, 0.9375rem)',
                    lineHeight: 'clamp(0.625rem, 0.515rem + 0.3521vw, 0.9375rem)',
                    fontFamily: 'inherit',
                    textTransform: 'inherit',
                  }}>
                  Assistenza gratuita
                </Typography>
                <Typography
                  sx={{
                    ml: '10px',
                    mt: '3px',
                    fontSize: 'font-size: clamp(0.625rem, 0.4049rem + 0.7042vw, 1.25rem)',
                    lineHeight: 'font-size: clamp(0.625rem, 0.4049rem + 0.7042vw, 1.25rem)',
                    fontFamily: 'inherit',
                    textTransform: 'inherit',
                  }}>
                  3490516905
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            component="a"
            href="tel:3490516905"
            sx={{
              border: '1px solid #0A92DE',
              borderRadius: '8px',
              px: 1,
              fontFamily: 'var(--font-red-hat-display)',
              textTransform: 'uppercase',
              color: '#0A92DE',
              background: '#0A92DE0F',
              '&:hover': {
                background: '#0A92DE',
                color: 'white',
              },
              display: { xs: 'block', md: 'none' },
            }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
              <Box
                sx={{
                  transform: 'rotate(10deg)',
                  mt: '5px',
                }}>
                <PhoneIcon className="phoneIconHeader" fontSize="small" sx={{ color: 'inherit' }} />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: 'clamp(0.625rem, 0.515rem + 0.3521vw, 0.9375rem)',
                    lineHeight: 'clamp(0.625rem, 0.515rem + 0.3521vw, 0.9375rem)',
                    fontFamily: 'inherit',
                    textTransform: 'inherit',
                  }}>
                  Assistenza
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ marginBottom: '20px', display: { xs: 'none', md: 'block', lg: 'block' } }}>
          {menuItems && menuItems.length > 0 && (
            <Box sx={{ bgcolor: '#1878B4', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
              {menuItems.map((i, index) => (
                <MenuItemPopover
                  key={i.id}
                  text={i.name}
                  index={index}
                  itemHref={i?.page?.path}
                  anchorEl={popoverAnchorEl}
                  setAnchorEl={setPopoverAnchorEl}
                  timeoutRef={timeoutPopoverRef}
                  subItems={i?.subItems || []}
                />
              ))}
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ mb: { xs: -10, md: -15, lg: -15 } }} />
      <MenuDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} menuItems={menuItems} />
    </>
  );
};

export default HeaderWithMenu;
