import React from 'react';
import Image from 'next/image';
import { Avatar, Box, Typography } from '@mui/material';

const BrokerListCard = ({ profileImageUrl, name, lastName, htmlDescription, provinces }) => {
  return (
    <Box
      sx={{
        boxShadow: '0px 0px 15px 1px rgba(0,0,0,0.11)',
        p: 2,
        flex: '1 1 calc(50% - 12px)',
        maxWidth: { sm: 'calc(50% - 12px)' },
        borderRadius: '5px',
        fontFamily: 'var(--font-red-hat-display)',
        fontSize: '18px',
        color: '#3a3a3a',
      }}>
      <Box sx={{ display: 'flex', gap: 4 }}>
        <div style={{ width: '130px', height: '130px', borderRadius: '65px', overflow: 'hidden' }}>
          <Image
            src={profileImageUrl}
            width={130}
            height={130}  
            alt={`${name} ${lastName} - Broker Mutui ${provinces}`}
          />
        </div>

        <Box sx={{ fontFamily: 'inherit', color: 'inherit', fontWeight: 'bold', fontSize: '20px', lineHeight: '25px' }}>
          <Typography
            sx={{
              m: 0,
              fontWeight: 'inherit',
              fontFamily: 'inherit',
              color: 'inherit',
              fontSize: 'inherit',
              lineHeight: 'inherit',
            }}>
            {name}
          </Typography>
          <Typography
            sx={{
              m: 0,
              fontWeight: 'inherit',
              fontFamily: 'inherit',
              color: 'inherit',
              fontSize: 'inherit',
              lineHeight: 'inherit',
            }}>
            {lastName}
          </Typography>
          <div className="star" style={{ position: 'relative', height: '0', width: '150px', padding: '0', paddingBottom: '50px' }}>
            <svg
              role="img"
              aria-labelledby="starRating"
              viewBox="0 0 251 46"
              xmlns="http://www.w3.org/2000/svg"
              style={{ position: 'absolute', height: '100%', width: '100%', left: '0', top: '0' }}>
              <title id="starRating" lang="en">
                5 out of five star rating on Trustpilot
              </title>
              <g className="tp-star">
                <path className="tp-star__canvas" fill="#00b67a" d="M0 46.330002h46.375586V0H0z"></path>
                <path
                  className="tp-star__shape"
                  d="M39.533936 19.711433L13.230239 38.80065l3.838216-11.797827L7.02115 19.711433h12.418975l3.837417-11.798624 3.837418 11.798624h12.418975zM23.2785 31.510075l7.183595-1.509576 2.862114 8.800152L23.2785 31.510075z"
                  fill="#FFF"></path>
              </g>
              <g className="tp-star">
                <path className="tp-star__canvas" fill="#00b67a" d="M51.24816 46.330002h46.375587V0H51.248161z"></path>
                <path className="tp-star__canvas--half" fill="#00b67a" d="M51.24816 46.330002h23.187793V0H51.248161z"></path>
                <path
                  className="tp-star__shape"
                  d="M74.990978 31.32991L81.150908 30 84 39l-9.660206-7.202786L64.30279 39l3.895636-11.840666L58 19.841466h12.605577L74.499595 8l3.895637 11.841466H91L74.990978 31.329909z"
                  fill="#FFF"></path>
              </g>
              <g className="tp-star">
                <path className="tp-star__canvas" fill="#00b67a" d="M102.532209 46.330002h46.375586V0h-46.375586z"></path>
                <path className="tp-star__canvas--half" fill="#00b67a" d="M102.532209 46.330002h23.187793V0h-23.187793z"></path>
                <path
                  className="tp-star__shape"
                  d="M142.066994 19.711433L115.763298 38.80065l3.838215-11.797827-10.047304-7.291391h12.418975l3.837418-11.798624 3.837417 11.798624h12.418975zM125.81156 31.510075l7.183595-1.509576 2.862113 8.800152-10.045708-7.290576z"
                  fill="#FFF"></path>
              </g>
              <g className="tp-star">
                <path className="tp-star__canvas" fill="#00b67a" d="M153.815458 46.330002h46.375586V0h-46.375586z"></path>
                <path className="tp-star__canvas--half" fill="#00b67a" d="M153.815458 46.330002h23.187793V0h-23.187793z"></path>
                <path
                  className="tp-star__shape"
                  d="M193.348355 19.711433L167.045457 38.80065l3.837417-11.797827-10.047303-7.291391h12.418974l3.837418-11.798624 3.837418 11.798624h12.418974zM177.09292 31.510075l7.183595-1.509576 2.862114 8.800152-10.045709-7.290576z"
                  fill="#FFF"></path>
              </g>
              <g className="tp-star">
                <path className="tp-star__canvas" fill="#00b67a" d="M205.064416 46.330002h46.375587V0h-46.375587z"></path>
                <path className="tp-star__canvas--half" fill="#00b67a" d="M205.064416 46.330002h23.187793V0h-23.187793z"></path>
                <path
                  className="tp-star__shape"
                  d="M244.597022 19.711433l-26.3029 19.089218 3.837419-11.797827-10.047304-7.291391h12.418974l3.837418-11.798624 3.837418 11.798624h12.418975zm-16.255436 11.798642l7.183595-1.509576 2.862114 8.800152-10.045709-7.290576z"
                  fill="#FFF"></path>
              </g>
            </svg>
          </div>
          <Box sx={{ m: 0, fontFamily: 'inherit', color: 'inherit', fontSize: '16px', mt: 0.5 }}>
            <Typography sx={{ m: 0, fontFamily: 'inherit', color: 'inherit', fontSize: 'inherit' }}>Province coperte:</Typography>
            <Typography
              sx={{
                m: 0,
                fontFamily: 'inherit',
                color: 'inherit',
                fontSize: 'inherit',
                fontWeight: 'bold',
              }}>
              {provinces}
            </Typography>
          </Box>
        </Box>
      </Box>
      <div dangerouslySetInnerHTML={{ __html: htmlDescription }}></div>
    </Box>
  );
};

export default BrokerListCard;
